import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { configureStore } from './redux/store';
import reportWebVitals from './reportWebVitals';
import history from './helpers/history';
import { Auth0Config, STRIPE_PUBLISH_KEY, STRIPE_CLIENT_KEY } from './constants/defaultValues';

const App = React.lazy(() => import('./App'));

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: Auth0Config.domain,
  clientId: Auth0Config.client_id,
  onRedirectCallback,
  authorizationParams: {
    // redirect_uri: window.location.origin,
    redirect_uri: Auth0Config.redirect_uri,
    audience: Auth0Config.audience,
    scope: 'email read:validation',
    // ...(config.audience ? { audience: config.audience } : null),
  },
};

const Main = () => {
  return (
    <Auth0Provider {...providerConfig}>
      <Provider store={configureStore()}>
        <Suspense fallback={<div className="loading" />}>
          <App />
        </Suspense>
      </Provider>
    </Auth0Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

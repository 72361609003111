import { UPDATE_REPORTS_ALLOWED, UPDATE_REPORTS_REMAINING } from '../contants';

const INIT_STATE = {
  allowedReports: 3,
  remaingReports: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_REPORTS_REMAINING:
      return { ...state, remaingReports: action.payload };
    case UPDATE_REPORTS_ALLOWED:
      return { ...state, allowedReports: action.payload };
    default:
      return { ...state };
  }
};
